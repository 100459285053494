import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const Video = () => (
  <Layout>
    <SEO title="Video" />

    <div className="relative">
      <div className="hidden lg:block">
        <StaticImage
          alt=""
          className="h-screen"
          placeholder="blurred"
          src="../images/video-bg.png"
        />
      </div>

      <div className="block lg:hidden">
        <StaticImage
          alt=""
          className="h-screen"
          placeholder="blurred"
          src="../images/video-bg-mobile.png"
          style={{ minHeight: 796 }}
        />
      </div>

      <div className="absolute bg-black bg-opacity-70 flex inset-0 items-center justify-center text-white">
        <div className="max-w-4xl mt-8 mx-auto px-4 text-center">
          <div className="mx-auto w-32 md:w-full">
            <StaticImage
              alt=""
              imgClassName="filter drop-shadow-2xl invert"
              placeholder="blurred"
              src="../images/logo.png"
            />
          </div>

          <p className="font-semibold my-4">HOW TO BEAST PRESENTS...</p>

          <h1 className="font-Oswald text-xl my-8 sm:text-2xl lg:text-3xl">
            How Men Are Unlocking Their Hidden Confidence
            &amp; Attracting Quality Women in less than 60 days.
          </h1>

          <p className="font-semibold my-4 uppercase">In this video you will learn:</p>

          <div className="px-2 md:px-16 lg:px-0">
            <div className="border-red border-2 bg-black mb-12 px-2 rounded-md transform -skew-x-12 md:px-4 lg:px-4">
              <div className="flex">
                <div className="bg-red flex items-center ml-2 mr-6 -my-3 px-4 step-shadow md:mr-8 md:px-8 xl:ml-8">
                  <span className="font-bold font-Oswald text-3xl md:text-5xl">01</span>
                </div>

                <div className="py-4 transform skew-x-12 2xl:py-8">
                  <p className="text-sm md:text-base lg:text-lg">
                    The #1 &quot;shortcut&quot; any man can use to take
                    his dating life to the next level.
                  </p>
                </div>
              </div>
            </div>

            <div className="border-red border-2 bg-black mb-12 px-2 rounded-md transform -skew-x-12 md:px-4 lg:px-4">
              <div className="flex">
                <div className="bg-red flex items-center ml-2 mr-6 -my-3 px-4 step-shadow md:mr-8 md:px-8 xl:ml-8">
                  <span className="font-bold font-Oswald text-3xl md:text-5xl">02</span>
                </div>

                <div className="py-4 transform skew-x-12 2xl:py-8">
                  <p className="text-sm md:text-base lg:text-lg">
                    The secret to feeling fully confident as a man.
                  </p>
                </div>
              </div>
            </div>

            <div className="border-red border-2 bg-black px-2 rounded-md transform -skew-x-12 md:px-4 lg:px-4">
              <div className="flex">
                <div className="bg-red flex items-center ml-2 mr-6 -my-3 px-4 step-shadow md:mr-8 md:px-8 xl:ml-8">
                  <span className="font-bold font-Oswald text-3xl md:text-5xl">03</span>
                </div>

                <div className="py-4 transform skew-x-12 2xl:py-8">
                  <p className="text-sm md:text-base lg:text-lg">
                    The &quot;alpha moves&quot; that ALL women respond to.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <Link
            className="bg-red inline-block mt-8 px-8 py-4 rounded-md shadow-md text-white transition-opacity hover:opacity-70"
            to="/watchnow"
          >
            Watch Video Now
          </Link>
        </div>
      </div>
    </div>
  </Layout>
);

export default Video;
